import React from "react";
import {
  BrowserRouter as Router,
  Routes as Switch,
  Route,
  // Redirect
} from "react-router-dom";

import { ToastContainer as DefaultToastContainer } from "react-toastify";

// Import Contexts
// import { theme, ThemeProvider } from "./context/ThemeProvider";
import SuspenseFallback from "./components/SuspenseFallback";
import ErrorBoundary from "./components/ErrorBoundary";
import NoPage from "./components/UnknownPage";

// Other assets
import "react-toastify/dist/ReactToastify.min.css";
import "./App.css";

// Import Layouts
import Home from "./layouts/Home";

const HomeMain = React.lazy(() => import("./pages/Home"));

// create a default container so we can override the styles
const ToastContainer = (props) => (
  <DefaultToastContainer style={{ zIndex: "1900" }} {...props} />
);

function App() {
  // const permissions = useSelector((state) => state.AuthReducer?.permissions);

  return (
    // <ThemeProvider theme={theme}>
    <ErrorBoundary>
      <React.Suspense fallback={<SuspenseFallback />}>
        <Router>
          <Switch>
            {/* <Route exact path="/" element={<Login />} /> */}

            <Route path="/" element={<Home />}>
              <Route index element={<HomeMain />} />
            </Route>

            <Route path="*" element={<NoPage />} />
          </Switch>
        </Router>
        <ToastContainer />
      </React.Suspense>
    </ErrorBoundary>
    // </ThemeProvider>
  );
}

export default App;
