import React from "react";

const Footer = () => {
  return (
    <div
      className="text-white flex flex-row items-center justify-center p-6 font-bold"
      style={{ backgroundColor: "#11635B", }}
    >
      Copyright &copy; {new Date().getFullYear()} CropGate
    </div>
  );
};

export default Footer;
