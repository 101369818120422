import React from "react";
import logo from "../assets/images/cropgate_black.png";

const Header = () => {
  return (
    <div
      className="flex justify-center items-center"
      style={{
        width: "100%",
        height: "80px",
        backgroundColor: "#fff",
        margin: "0px 0",
        padding: "10px 20px 10px",
      }}
    >
      <img
        src={logo}
        alt=""
        // width={"549px"}
        // height={"526px"}
        className="borrder"
        style={{
          width: "180px",
        }}
      />
    </div>
  );
};

export default Header;
